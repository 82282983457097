<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style lang="scss">
  // CoreUI Icons Set
  @import '~@coreui/icons/css/coreui-icons.min.css';
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Flag Icons Set */
  @import '~flag-icon-css/css/flag-icon.min.css';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  // Import Main styles for this application
  @import 'assets/scss/style';
  .app,
  app-dashboard,
  app-root {
    display: flex;
    flex-direction: column;
    min-height: 76vh;
  }
  .form-error{
    color: red;
  }
  .form-control:focus {
    box-shadow: none;
    border-color: #5c6873;
  }
  .vgt-table{
    font-size: 14px !important;
  }
  .no-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .btn-price-tag-item-danger {
    display: block;
    float: right;
    margin-top: 30px;
  }
  .field-array {
    clear: both;
  }
  .btn-price-tag-item-primary {
    float: left;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media print {
    .b-toaster {
      display: none;
    }
  }
  tr.under_considaration {
    background: none repeat scroll 0 0 #FF8293 !important;
  }
  tr.considerated {
    background: none repeat scroll 0 0 #FFFEC9 !important;
  }
  tr.during_delivery {
    background: none repeat scroll 0 0 #BFFFBF !important;
  }
  tr.delivered {
    background: none repeat scroll 0 0 #2df700 !important;
  }
  tr.closed {
    background: none repeat scroll 0 0 #E5F1F4 !important;
  }
  tr.archive {
    background: none repeat scroll 0 0 #ffa3ed !important;
  }
  tr.store-out {
    background-color: #ffcccc;
  }
  tr.store-in {
    background-color: #ccffcc;
  }
</style>
