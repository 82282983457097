<template>
  <image-upload
    :id="id"
    :type="type"
    :subtype="subtype"
    :suffix="suffix"
    :disabled="disabled"
    @filesListUpdated="filesListUpdated"
  />
</template>

<script>
  import { abstractField } from 'vue-form-generator'
  import ImageUpload from "../ImageUpload";

  export default {
    components: {
      ImageUpload,
    },
    mixins: [abstractField],
    data () {
      return {
        type: '',
        suffix: 'original',
        subtype: 0,
        id: null,
      }
    },
    beforeMount() {
      this.type = this.schema.imageType;
      if (this.schema.imageSubtype) {
        this.subtype = this.schema.imageSubtype;
      }
      if (this.model.id) {
        this.id = this.model.id;
      }
      if (this.schema.suffix) {
        this.suffix = this.schema.suffix;
      }
    },
    methods: {
      filesListUpdated (files) {
        this.value = files
      }
    }
  }
</script>
