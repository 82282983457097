<template>
  <date-picker
    v-model="date"
    :config="dateOptions"
    @dp-change="updateDate"
    :placeholder="schema.placeholder ? schema.placeholder : ''"
  />
</template>

<script>
  import { abstractField } from 'vue-form-generator'
  import datePicker from 'vue-bootstrap-datetimepicker';
  import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
  import moment from "moment";

  export default {
    components: {
      datePicker
    },
    data: () => {
      return {
        date: null,
        dateOptions: {
          format: 'DD.MM.YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          locale: 'ru',
        }
      }
    },
    beforeMount() {
      this.date = this.value ? moment(this.value).format('DD.MM.YYYY') : null;
    },
    methods: {
      updateDate() {
        this.value = moment(this.date, 'DD.MM.YYYY').toDate();
      }
    },
    mixins: [abstractField],
  }
</script>
