import Vue from 'vue';
import VueI18n from 'vue-i18n';
import userRuMessages from '../views/user/i18n/ru';
import userEnMessages from '../views/user/i18n/en';
import userUkMessages from '../views/user/i18n/uk';
import ucsRuMessages from '../views/ucs/i18n/ru';
import ucsEnMessages from '../views/ucs/i18n/en';
import ucsUkMessages from '../views/ucs/i18n/uk';
import discountRuMessages from '../views/discount/i18n/ru';
import discountEnMessages from '../views/discount/i18n/en';
import discountUkMessages from '../views/discount/i18n/uk';
import structureRuMessages from '../views/structure/i18n/ru';
import structureEnMessages from '../views/structure/i18n/en';
import structureUkMessages from '../views/structure/i18n/uk';
import moduleRuMessages from '../views/module/i18n/ru';
import moduleEnMessages from '../views/module/i18n/en';
import moduleUkMessages from '../views/module/i18n/uk';
import seoRuMessages from '../views/seo/i18n/ru';
import seoEnMessages from '../views/seo/i18n/en';
import seoUkMessages from '../views/seo/i18n/uk';
import emailRuMessages from '../views/email/i18n/ru';
import emailEnMessages from '../views/email/i18n/en';
import emailUkMessages from '../views/email/i18n/uk';
import filterRuMessages from '../views/filter/i18n/ru';
import filterEnMessages from '../views/filter/i18n/en';
import filterUkMessages from '../views/filter/i18n/uk';
import orderRuMessages from '../views/order/i18n/ru';
import orderEnMessages from '../views/order/i18n/en';
import orderUkMessages from '../views/order/i18n/uk';
import statisticRuMessages from '../views/statistic/i18n/ru';
import statisticEnMessages from '../views/statistic/i18n/en';
import statisticUkMessages from '../views/statistic/i18n/uk';
import storeRuMessages from '../views/store/i18n/ru';
import storeEnMessages from '../views/store/i18n/en';
import storeUkMessages from '../views/store/i18n/uk';
import logisticsRuMessages from '../views/logistics/i18n/ru';
import logisticsEnMessages from '../views/logistics/i18n/en';
import logisticsUkMessages from '../views/logistics/i18n/uk';


Vue.use(VueI18n);

const messages = {
  'ru': Object.assign({
    home: "Главная",
    welcomeMsg: 'Welcome to Your Vue.js App',
    extendedSearch: "Расширенный поиск",
    search: "Поиск",
    save: "Сохранить",
    images: "Фото",
    SignInToYourAccount: "Вход в систему",
    Login: "Вход",
    username: "Логин",
    usernameRequired: "Логин обязательное поле",
    usernameMaxLength: "Слишком длинный логин",
    password: "Пароль",
    passwordRequired: "Пароль обязательное поле",
    passwordMaxLength: "Слишком длинный пароль",
    wrongLoginOrPassword: "Неправильный логин или пароль",
    rowsPerPage: "Элементов на странице",
    allItemPerPage: "Все",
    next: "След",
    prev: "Пред",
    newOrders: "Новых заказов",
    dailyOrders: "Заказов за день",
    users: "Пользователей",
    newUsers: "Новых пользователей",
    statusRequired: "Статус обязательное поле",
    uploadPhoto: "Загрузить фото",
    dataWasSuccessfullySaved: "Данные успешно сохранены",
    errorOnDataSaving: "Ошибка при сохранении данных",
    statusWasSuccessfullySaved: "Статус успешно обновлен",
    errorOnStatusSaving: "Ошибка при обновлении статуса",
    dataWasSuccessfullyDeleted: "Данные успешно удалены",
    errorOnDataDelete: "Ошибка при удалении данных",
    areYouSureYouWantDelete: "Вы действительно хотите удалить?"
  },
  userRuMessages,
  ucsRuMessages,
  discountRuMessages,
  structureRuMessages,
  moduleRuMessages,
  seoRuMessages,
  orderRuMessages,
  statisticRuMessages,
  logisticsRuMessages,
  emailRuMessages,
  filterRuMessages,
  storeRuMessages
),
  'en': Object.assign({
    home: "Home",
    welcomeMsg: 'Welcome to Your Vue.js App',
    extendedSearch: "Extended search",
    search: "Search",
    save: "Save",
    images: "Images",
  }, userEnMessages,
  ucsEnMessages,
  discountEnMessages,
  structureEnMessages,
  moduleEnMessages,
  seoEnMessages,
  orderEnMessages,
  statisticEnMessages,
  logisticsEnMessages,
  emailEnMessages,
  filterEnMessages,
  storeEnMessages
),
  'uk':  Object.assign({
    home: "Головна",
    welcomeMsg: 'Welcome to Your Vue.js App',
    extendedSearch: "Розширений пошук",
    search: "Пошук",
    save: "Зберегти",
    images: "Фото",
  }, userUkMessages,
  ucsUkMessages,
  discountUkMessages,
  structureUkMessages,
  moduleUkMessages,
  seoUkMessages,
  orderUkMessages,
  statisticUkMessages,
  logisticsUkMessages,
  emailUkMessages,
  filterUkMessages,
  storeUkMessages,
),
};

const i18n = new VueI18n({
  locale: 'ru',
  fallbackLocale: 'en',
  messages,
});

/*
(async () => {
  const rawMessages = await APIService.get('translation');
  const messages = {
    'ru': rawMessages
      .filter(message => message.language === 'ru')
      .map(message => {
        let result = {}
        result[message.code] = message.translation;
        return result;
      }),
    'en': rawMessages
      .filter(message => message.language === 'en')
      .map(message => {
        let result = {}
        result[message.code] = message.translation;
        return result;
      }),
    'uk':  rawMessages
      .filter(message => message.language === 'uk')
      .map(message => {
        let result = {}
        result[message.code] = message.translation;
        return result;
      }),
  };
  i18n.setLocaleMessage('ru', messages.ru);
  // i18n.setLocaleMessage('en', messages.en);
  // i18n.setLocaleMessage('uk', messages.uk);
  console.log('i18n', messages.ru, i18n.getLocaleMessage('ru'));
})();
*/

export default i18n;
