<template>
  <div>
    <b-form-group v-if="loaded">
      <b-card v-for="item in items" no-body class="mb-1" :key="item.header">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="'accordion-' + item.header">
            <span @click="(e) => handleGroupClick(e, item)">
              <b-form-checkbox
                v-if="item.value"
                v-model="headerValues"
                :value="item.value"
                :id="item.header"
                style="width: auto; display: inline"
              >
                {{ item.header }}
              </b-form-checkbox>
              <span v-else>
                {{ item.header }}
              </span>
            </span>
          </b-button>
        </b-card-header>
        <b-collapse :id="'accordion-' + item.header" :accordion="'accordion-' + item.header">
          <b-card-body>
            <b-card-text>
              <b-row>
                <b-col
                  v-for="subitem in item.items"
                  :key="subitem.header"
                  sm="12"
                  md="4"
                  lg="3"
                >
                  <b-form-checkbox v-model="value" :value="subitem">
                    {{ getLabel(subitem) }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-form-group>
  </div>
</template>

<script>
  import { abstractField } from 'vue-form-generator';

  export default {
    mixins: [abstractField],
    data () {
      return {
        items: [],
        headerValues: [],
        labelField: 'name',
        valueField: 'id',
        loaded: false,
      }
    },
    async beforeMount() {
      if (this.schema.items) {
        this.items = this.schema.items instanceof Promise
          ? await this.schema.items
          : this.schema.items;
      }
      this.labelField = this.schema.labelField ? this.schema.labelField : 'name';
      this.valueField = this.schema.valueField ? this.schema.valueField : 'id';
      if (this.value && this.value.length === 0) {
        if (this.schema.checked) {
          this.items.forEach(item => {
            item.items.forEach(subitem => {
              this.value.push(subitem);
            })
            if (item.value) {
              this.headerValues.push(item.value);
            }
          })
        }
      }
      this.loaded = true;
    },
    methods: {
      handleGroupClick(event, item) {
        event.stopPropagation();
        this.setCheckboxes(item);
      },
      setCheckboxes(item) {
        const alreadySelected = this.headerValues.includes(item.value);
        if (alreadySelected) {
            this.value = this.value.filter((el) => !item.items.map(subitem => this.getValue(subitem)).includes(this.getValue(el)));
        }
        else {
          item.items.forEach(item => {
            if (this.value.filter(valueItem => this.getValue(valueItem) === this.getValue(item)).length === 0) {
              this.value.push(item);
            }
          })
        }
      },
      getLabel (item) {
        return item[this.labelField] ? item[this.labelField] : '';
      },
      getValue (item) {
        return item[this.valueField] ? item[this.valueField] : '';
      }
    }
  }
</script>

<style lang="scss" scoped>
  .btn-secondary, .btn-secondary:focus, .btn-secondary:active {
    background: transparent !important;
    border: none !important;
    text-align: left !important;
    box-shadow: none !important;
  }
</style>
