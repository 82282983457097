<template>
  <ckeditor 
    v-model="value"
    editorUrl="https://cdn.ckeditor.com/4.14.0/full-all/ckeditor.js"
  />
</template>

<script>
  import { abstractField } from 'vue-form-generator'
  export default {
    mixins: [abstractField],
  }
</script>
