import Vue from 'vue'
import Router from 'vue-router'

// Containers
const DefaultContainer = () => import('@/containers/DefaultContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

const Charts = () => import('@/views/Charts')
const Widgets = () => import('@/views/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const GridTables = () => import('@/views/base/GridTables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Structure
const MenusList = () => import('@/views/structure/MenusList')
const PagesList = () => import('@/views/structure/PagesList')
const BannersList = () => import('@/views/structure/BannersList')
const AddPage = () => import('@/views/structure/AddPage')
const AddMenu = () => import('@/views/structure/AddMenu')
const UpdatePage = () => import('@/views/structure/UpdatePage')
const UpdateMenu = () => import('@/views/structure/UpdateMenu')

// Views - Module
const ArticlesList = () => import('@/views/module/ArticlesList')
const NewsList = () => import('@/views/module/NewsList')
const AddArticle = () => import('@/views/module/AddArticle')
const AddNews = () => import('@/views/module/AddNews')
const UpdateArticle = () => import('@/views/module/UpdateArticle')
const UpdateNews = () => import('@/views/module/UpdateNews')

// Views - SEO
const SitemapView = () => import('@/views/seo/SitemapView')

// Views - Email
const SubscribersList = () => import('@/views/email/SubscribersList')
const MailGroupsList = () => import('@/views/email/MailGroupsList')
const NewslettersList = () => import('@/views/email/NewslettersList')
const AddMailGroup = () => import('@/views/email/AddMailGroup')
const AddSubscriber = () => import('@/views/email/AddSubscriber')
const UpdateMailGroup = () => import('@/views/email/UpdateMailGroup')
const UpdateSubscriber = () => import('@/views/email/UpdateSubscriber')

// Views - UCS
const TagList = () => import('@/views/ucs/TagList')
const TagItemList = () => import('@/views/ucs/TagItemList')
const TagItemRecalculationList = () => import('@/views/ucs/TagItemRecalculationList')
const ProductStatusList = () => import('@/views/ucs/ProductStatusList')
const ProductList = () => import('@/views/ucs/ProductList')
const AddProduct = () => import('@/views/ucs/AddProduct')
const UpdateProduct = () => import('@/views/ucs/UpdateProduct')
const AddTag = () => import('@/views/ucs/AddTag')
const AddTagItem = () => import('@/views/ucs/AddTagItem')
const AddTagItemRecalculation = () => import('@/views/ucs/AddTagItemRecalculation')
const AddProductStatus = () => import('@/views/ucs/AddProductStatus')
const UpdateTag = () => import('@/views/ucs/UpdateTag')
const UpdateTagItem = () => import('@/views/ucs/UpdateTagItem')
const UpdateProductStatus = () => import('@/views/ucs/UpdateProductStatus')
const TagItemDiscount = () => import('@/views/ucs/TagItemDiscount')
const TagItemSort = () => import('@/views/ucs/TagItemSort')

// Views - Filter
const FiltersList = () => import('@/views/filter/FiltersList')
const TagItemsFiltersList = () => import('@/views/filter/TagItemsFiltersList')
const AddTagItemFilter = () => import('@/views/filter/AddTagItemFilter')
const SortFilterTagItems = () => import('@/views/filter/SortFilterTagItems')
const AddFilter = () => import('@/views/filter/AddFilter')
const UpdateFilter = () => import('@/views/filter/UpdateFilter')

// Views - Discount
const DiscountProductsList = () => import('@/views/discount/DiscountProductsList')

// Views - Order
const OrderList = () => import('@/views/order/OrderList')
const UpdateOrder = () => import('@/views/order/UpdateOrder')
const ViewOrder = () => import('@/views/order/ViewOrder')
const PrintOrder = () => import('@/views/order/PrintOrder')

// Views - Order History
const OrderHistoryList = () => import('@/views/order/OrderHistoryList')
const ViewOrderHistory = () => import('@/views/order/ViewOrderHistory')

// Views - Logistics
const LogisticsList = () => import('@/views/logistics/LogisticsList')
const RecipientsList = () => import('@/views/logistics/RecipientsList')
const OrdersPositionsList = () => import('@/views/logistics/OrdersPositionsList')
const OrdersPrint = () => import('@/views/logistics/OrdersPrint')
const DistributionList = () => import('@/views/logistics/DistributionList')
const SmsTextList = () => import('@/views/logistics/SmsTextList')
const ManagerList = () => import('@/views/logistics/ManagerList')
const DeliveryList = () => import('@/views/logistics/DeliveryList')
const PaymentMethodList = () => import('@/views/logistics/PaymentMethodList')
const AddManager = () => import('@/views/logistics/AddManager')
const AddDelivery = () => import('@/views/logistics/AddDelivery')
const AddPaymentMethod = () => import('@/views/logistics/AddPaymentMethod')
const UpdateManager = () => import('@/views/logistics/UpdateManager')
const UpdateDelivery = () => import('@/views/logistics/UpdateDelivery')
const UpdatePaymentMethod = () => import('@/views/logistics/UpdatePaymentMethod')

// Views - Statistic
const SearchTermList = () => import('@/views/statistic/SearchTermList')
const StatisticProductList = () => import('@/views/statistic/StatisticProductList')

// Views - System
const SystemList = () => import('@/views/system/SystemList')

// Views - Stores
const StoresList = () => import('@/views/store/StoresList')
const AddStore = () => import('@/views/store/AddStore')
const UpdateStore = () => import('@/views/store/UpdateStore')
const StoreItemsList = () => import('@/views/store/StoreItemsList')
const StoreHistoryList = () => import('@/views/store/StoreHistoryList')
const ViewStoreHistory = () => import('@/views/store/ViewStoreHistory')
const StoreChangeLogItemsAdd = () => import('@/views/store/StoreChangeLogItemsAdd')
const StoreChangeLogList = () => import('@/views/store/StoreChangeLogList')
const ViewStoreChangeLog = () => import('@/views/store/ViewStoreChangeLog')

// Views - Users
const UserList = () => import('@/views/user/UserList')
const AddUser = () => import('@/views/user/AddUser')
const UpdateUser = () => import('@/views/user/UpdateUser')
const AddUserAlert = () => import('@/views/user/AddUserAlert')
const UpdateUserAlert = () => import('@/views/user/UpdateUserAlert')
const UserAlertsList = () => import('@/views/user/UserAlertsList')

// Views - Translation
const TranslationList = () => import('@/views/translation/TranslationList')
const AddTranslation = () => import('@/views/translation/AddTranslation')
const UpdateTranslation = () => import('@/views/translation/UpdateTranslation')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const Flags = () => import('@/views/icons/Flags')
const FontAwesome = () => import('@/views/icons/FontAwesome')
const SimpleLineIcons = () => import('@/views/icons/SimpleLineIcons')
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Logout = () => import('@/views/pages/Logout')
const Register = () => import('@/views/pages/Register')

// // Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: DefaultContainer,
      meta: {
        label: 'home',
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            hide: true
          },
        },
        // {
        //   path: 'theme',
        //   redirect: '/theme/colors',
        //   name: 'Theme',
        //   component: {
        //     render (c) { return c('router-view') }
        //   },
        //   children: [
        //     {
        //       path: 'colors',
        //       name: 'Colors',
        //       component: Colors
        //     },
        //     {
        //       path: 'typography',
        //       name: 'Typography',
        //       component: Typography
        //     }
        //   ]
        // },
        {
          path: 'grid-tables',
          name: 'GridTables',
          component: GridTables
        },
        {
          path: 'charts',
          name: 'Charts',
          component: Charts
        },
        {
          path: 'widgets',
          name: 'Widgets',
          component: Widgets
        },
        /*
        {
          path: 'users',
          meta: { label: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              component: Users,
            },
            {
              path: ':id',
              meta: { label: 'User Details'},
              name: 'User',
              component: User,
            },
          ]
        },
        */
        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'forms',
              name: 'Forms',
              component: Forms
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tables',
              name: 'Tables',
              component: Tables
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            // {
            //   path: 'jumbotrons',
            //   name: 'Jumbotrons',
            //   component: Jumbotrons
            // },
            // {
            //   path: 'list-groups',
            //   name: 'List Groups',
            //   component: ListGroups
            // },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            // {
            //   path: 'paginations',
            //   name: 'Paginations',
            //   component: Paginations
            // },
            // {
            //   path: 'popovers',
            //   name: 'Popovers',
            //   component: Popovers
            // },
            // {
            //   path: 'progress-bars',
            //   name: 'Progress Bars',
            //   component: ProgressBars
            // },
            // {
            //   path: 'tooltips',
            //   name: 'Tooltips',
            //   component: Tooltips
            // }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            }
          ]
        },
        {
          path: 'structure',
          redirect: '/structure/page',
          name: 'Structure',
          meta: {
            label: 'structure',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'menu',
              redirect: '/structure/menu/list',
              name: 'menus',
              meta: {
                label: 'menu',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add/:type/:parentId?',
                  name: 'AddMenu',
                  component: AddMenu,
                  props: true,
                  meta: {
                    label: 'addMenu',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateMenu',
                  component: UpdateMenu,
                  props: true,
                  meta: {
                    label: 'updateMenu',
                  },
                },
                {
                  path: 'list/:type',
                  name: 'MenuList',
                  component: MenusList,
                  props: true,
                  meta: {
                    hide: true,
                  }
                },
              ]
            },
            {
              path: 'page',
              redirect: '/structure/page/list',
              name: 'Pages',
              meta: {
                label: 'pageList',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddPage',
                  component: AddPage,
                  meta: {
                    label: 'addPage',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdatePage',
                  component: UpdatePage,
                  props: true,
                  meta: {
                    label: 'updatePage',
                  },
                },
                {
                  path: 'list',
                  name: 'PagesList',
                  component: PagesList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: '/structure/banners/:type',
              name: 'BannersLists',
              component: BannersList,
              props: true,
              meta: {
                label: 'banners',
              },
            },
          ]
        },
        {
          path: 'module',
          redirect: '/module/article',
          name: 'Modules',
          meta: {
            label: 'modules',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'article',
              name: 'ArticlesList',
              meta: {
                label: 'articlesList',
              },
              redirect: '/module/article/list',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddArticle',
                  component: AddArticle,
                  meta: {
                    label: 'addArticle',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateArticle',
                  component: UpdateArticle,
                  props: true,
                  meta: {
                    label: 'updateArticle',
                  },
                },
                {
                  path: 'list',
                  name: 'ArticlesList',
                  component: ArticlesList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'news',
              name: 'NewsList',
              redirect: '/module/news/list',
              meta: {
                label: 'newsList',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddNews',
                  component: AddNews,
                  meta: {
                    label: 'addNews',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateNews',
                  component: UpdateNews,
                  props: true,
                  meta: {
                    label: 'updateNews',
                  },
                },
                {
                  path: 'list',
                  name: 'NewsList',
                  component: NewsList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
          ]
        },
        {
          path: 'discount',
          redirect: '/discount/product',
          name: 'Discounts',
          meta: {
            label: 'discounts',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'product',
              name: 'DiscountProductsList',
              meta: {
                label: 'discountProductsList',
              },
              redirect: '/discount/product/list',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                // {
                //   path: 'add',
                //   name: 'AddArticle',
                //   component: AddArticle,
                //   meta: {
                //     label: 'addArticle',
                //   },
                // },
                // {
                //   path: 'update/:id',
                //   name: 'UpdateArticle',
                //   component: UpdateArticle,
                //   props: true,
                //   meta: {
                //     label: 'updateArticle',
                //   },
                // },
                {
                  path: 'list',
                  name: 'DiscountProductsList',
                  component: DiscountProductsList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
          ]
        },
        {
          path: 'seo',
          redirect: '/seo/sitemap',
          name: 'SEO',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'sitemap',
              name: 'SitemapView',
              component: SitemapView,
              meta: {
                label: 'uploadSiteMap',
              },
            },
          ]
        },
        {
          path: 'email',
          redirect: '/email/group',
          name: 'Email',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'group',
              name: 'EmailGroups',
              redirect: '/email/group/list',
              component: {
                render (c) { return c('router-view') }
              },
              meta: {
                label: 'mailGroupList',
              },
              children: [
                {
                  path: 'add',
                  name: 'AddMailGroup',
                  component: AddMailGroup,
                  meta: {
                    label: 'addMailGroup',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateMailGroup',
                  component: UpdateMailGroup,
                  props: true,
                  meta: {
                    label: 'editMailGroup',
                  },
                },
                {
                  path: 'list',
                  name: 'MailGroupsList',
                  component: MailGroupsList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              // path: 'subscriber',
              // name: 'SubscribersList',
              // component: SubscribersList,
              path: 'subscriber',
              name: 'EmailSubscribers',
              meta: {
                label: 'subscribersList',
              },
              redirect: '/email/subscriber/list',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddSubscriber',
                  component: AddSubscriber,
                  meta: {
                    label: 'addSubscriber',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateSubscriber',
                  component: UpdateSubscriber,
                  props: true,
                  meta: {
                    label: 'editSubscriber',
                  },
                },
                {
                  path: 'list',
                  name: 'SubscribersList',
                  component: SubscribersList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'newsletter',
              name: 'NewslettersList',
              component: NewslettersList,
              meta: {
                label: 'addNewsletter',
              },
            },
          ]
        },
        {
          path: 'ucs',
          redirect: '/ucs/tag',
          name: 'UCS',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'tag',
              name: 'TagList',
              meta: {
                label: 'tagList',
              },
              redirect: '/ucs/tag/list',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddTag',
                  component: AddTag,
                  meta: {
                    label: 'addTag',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateTag',
                  component: UpdateTag,
                  props: true,
                  meta: {
                    label: 'editTag',
                  },
                },
                {
                  path: 'list',
                  name: 'TagList',
                  component: TagList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'tag-item',
              name: 'TagItemList',
              meta: {
                label: 'tagItemList',
              },
              redirect: '/ucs/tag-item/list/1',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add/:tagId/:parentId?',
                  name: 'AddTagItem',
                  component: AddTagItem,
                  props: true,
                  meta: {
                    label: 'addTag',
                  }
                },
                {
                  path: 'update/:id',
                  name: 'UpdateTagItem',
                  component: UpdateTagItem,
                  props: true,
                  meta: {
                    label: 'editTag',
                  }
                },
                {
                  path: 'list/:tagId',
                  name: 'TagItemList',
                  component: TagItemList,
                  props: true,
                  meta: {
                    hide: true,
                  }
                },
                {
                  path: 'discount/:id',
                  name: 'TagItemDiscount',
                  component: TagItemDiscount,
                  props: true,
                  meta: {
                    label: 'discountsGrid',
                  }
                },
                {
                  path: 'sort/:id',
                  name: 'TagItemSort',
                  component: TagItemSort,
                  props: true,
                  meta: {
                    label: 'tagItemSort',
                  }
                },
                {
                  path: 'tag-item-recalculation',
                  name: 'TagItemRecalculationListRedirect',
                  redirect: '/ucs/tag-item-recalculation/list',
                  meta: {
                    hide: true,
                  },
                  component: {
                    render (c) { return c('router-view') }
                  },
                  children: [
                    {
                      path: 'add/:tagItemId',
                      name: 'AddTagItemRecalculation',
                      component: AddTagItemRecalculation,
                      props: true,
                      meta: {
                        label: 'addTagItemRecalculation',
                      }
                    },
                    {
                      path: 'list/:tagItemId',
                      name: 'TagItemRecalculationList',
                      component: TagItemRecalculationList,
                      props: true,
                      meta: {
                        label: 'tagItemRecalculationList',
                      }
                    },
                  ]
                },
              ]
            },
            {
              path: 'product-status',
              name: 'ProductStatusList',
              redirect: '/ucs/product-status/list',
              meta: {
                label: 'productStatusList',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddProductStatus',
                  component: AddProductStatus,
                  meta: {
                    label: 'addProductStatus',
                  }
                },
                {
                  path: 'update/:id',
                  name: 'UpdateProductStatus',
                  component: UpdateProductStatus,
                  props: true,
                  meta: {
                    label: 'updateProductStatus',
                  }
                },
                {
                  path: 'list',
                  name: 'ProductStatusList',
                  component: ProductStatusList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'product',
              name: 'ProductList',
              redirect: '/ucs/product/list',
              meta: {
                label: 'productsList',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddProduct',
                  component: AddProduct,
                  meta: {
                    label: 'addProduct',
                  }
                },
                {
                  path: 'update/:id',
                  name: 'UpdateProduct',
                  component: UpdateProduct,
                  props: true,
                  meta: {
                    label: 'editProduct',
                  }
                },
                {
                  path: 'list',
                  name: 'ProductList',
                  component: ProductList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
          ]
        },
        {
          path: 'filter',
          redirect: '/filter/name/list',
          name: 'Filters',
          meta: {
            label: 'filters',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'name',
              name: 'FiltersList',
              meta: {
                label: 'filtersList',
              },
              redirect: '/filter/name/list',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddFilter',
                  component: AddFilter,
                  meta: {
                    label: 'addFilter',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateFilter',
                  component: UpdateFilter,
                  props: true,
                  meta: {
                    label: 'updateFilter',
                  },
                },
                {
                  path: 'list',
                  name: 'FiltersList',
                  component: FiltersList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'tag-item',
              name: 'Tag items filter',
              meta: {
                label: 'filtersList',
              },
              redirect: '/filter/tag-item/list',
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add/:tagItemId',
                  name: 'AddTagItemFilter',
                  component: AddTagItemFilter,
                  props: true,
                  meta: {
                    hide: true,
                  },
                },
                // {
                //   path: 'update/:id',
                //   name: 'UpdateFilter',
                //   component: UpdateFilter,
                //   props: true,
                //   meta: {
                //     label: 'updateFilter',
                //   },
                // },
                {
                  path: 'list/:tagItemId',
                  name: 'TagItemsFiltersList',
                  component: TagItemsFiltersList,
                  props: true,
                  meta: {
                    hide: true,
                  },
                },
                {
                  path: 'sort/:filterTagItemRelationId',
                  name: 'SortFilterTagItems',
                  component: SortFilterTagItems,
                  props: true,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
          ]
        },
        {
          path: 'order',
          name: 'OrderList',
          redirect: '/order/list',
          meta: {
            label: 'ordersList',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'view/:id',
              name: 'ViewOrder',
              component: ViewOrder,
              props: true,
              meta: {
                label: 'viewOrder',
              },
            },
            {
              path: 'update/:id',
              name: 'UpdateOrder',
              component: UpdateOrder,
              props: true,
              meta: {
                label: 'updateOrder',
              },
            },
            {
              path: 'print/:id',
              name: 'PrintOrder',
              component: PrintOrder,
              props: true,
            },
            {
              path: 'list',
              name: 'OrderList',
              component: OrderList,
              meta: {
                hide: true,
              },
            },
          ]
        },
        {
          path: 'order-history',
          name: 'OrderHistoryList',
          redirect: '/order-history/list',
          meta: {
            label: 'ordersHistoryList',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'view/:id/:orderHistoryId',
              name: 'ViewOrderHistory',
              component: ViewOrderHistory,
              props: true,
              meta: {
                label: 'viewOrderHistory',
              },
            },
            {
              path: 'list/:id',
              name: 'OrderHistoryList',
              component: OrderHistoryList,
              meta: {
                hide: true,
              },
            },
          ]
        },
        {
          path: '/logistics',
          redirect: '/logistics/logistics',
          name: 'Logistics',
          meta: {
            label: 'logisticsList',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'logistics',
              name: 'LogisticsList',
              component: LogisticsList,
              meta: {
                hide: true,
              }
            },
            {
              path: 'orders-print',
              name: 'OrdersPrint',
              component: OrdersPrint,
              meta: {
                label: 'OrdersPrint',
              }
            },
            {
              path: 'recipients',
              name: 'RecipientsList',
              component: RecipientsList,
              meta: {
                label: 'RecipientsList',
              }
            },
            {
              path: 'orders-positions',
              name: 'OrdersPositionsList',
              component: OrdersPositionsList,
              meta: {
                label: 'OrdersPositionsList',
              }
            },
            {
              path: 'distribution',
              name: 'DistributionList',
              component: DistributionList,
              meta: {
                label: 'DistributionList',
              }
            },
            {
              path: 'sms-report',
              name: 'SmsTextList',
              component: SmsTextList,
              meta: {
                label: 'SmsTextList',
              }
            },
            {
              path: 'manager',
              name: 'ManagerList',
              redirect: '/logistics/manager/list',
              meta: {
                label: 'managerList',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddManager',
                  component: AddManager,
                  meta: {
                    label: 'addManager',
                  }
                },
                {
                  path: 'update/:id',
                  name: 'UpdateManager',
                  component: UpdateManager,
                  props: true,
                  meta: {
                    label: 'updateManager',
                  }
                },
                {
                  path: 'list',
                  name: 'ManagerList',
                  component: ManagerList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'delivery',
              name: 'DeliveryList',
              redirect: '/logistics/delivery/list',
              meta: {
                label: 'deliveryList',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddDelivery',
                  component: AddDelivery,
                  meta: {
                    label: 'addDelivery',
                  }
                },
                {
                  path: 'update/:id',
                  name: 'UpdateDelivery',
                  component: UpdateDelivery,
                  props: true,
                  meta: {
                    label: 'updateDelivery',
                  }
                },
                {
                  path: 'list',
                  name: 'DeliveryList',
                  component: DeliveryList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'payment-method',
              name: 'PaymentMethodList',
              redirect: '/logistics/payment-method/list',
              meta: {
                label: 'paymentMethodsList',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddPaymentMethod',
                  component: AddPaymentMethod,
                  meta: {
                    label: 'addPaymentMethod',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdatePaymentMethod',
                  component: UpdatePaymentMethod,
                  props: true,
                  meta: {
                    label: 'updatePaymentMethod',
                  },
                },
                {
                  path: 'list',
                  name: 'PaymentMethodList',
                  component: PaymentMethodList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
          ]
        },
        {
          path: 'store',
          redirect: '/store/store/list',
          name: 'Stores',
          meta: {
            label: 'storeList',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'store',
              name: 'StoreList',
              redirect: '/store/store/list',
              meta: {
                hide: true,
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddStore',
                  component: AddStore,
                  meta: {
                    label: 'addStore',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateStore',
                  component: UpdateStore,
                  props: true,
                  meta: {
                    label: 'editStore',
                  },
                },
                {
                  path: 'list',
                  name: 'StoresList',
                  component: StoresList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'store-item/list/:storeId',
              name: 'StoreItemsList',
              component: StoreItemsList,
              props: true,
              meta: {
                hide: true,
              },
            },
            {
              path: 'store-item/add/:storeId',
              name: 'StoreChangeLogItemsAdd',
              component: StoreChangeLogItemsAdd,
              props: true,
              meta: {
                hide: true,
              },
            },
            {
              path: 'store-history/list/:storeId',
              name: 'StoreHistoryList',
              component: StoreHistoryList,
              props: true,
              // meta: {
              //   hide: true,
              // },
            },
            {
              path: 'store-history/view/:storeHistoryId',
              name: 'ViewStoreHistory',
              component: ViewStoreHistory,
              props: true,
              // meta: {
              //   hide: true,
              // },
            },
            {
              path: 'store-change-log/list/:storeId',
              name: 'StoreChangeLogList',
              component: StoreChangeLogList,
              props: true,
              // meta: {
              //   hide: true,
              // },
            },
            {
              path: 'store-change-log/view/:storeChangeLogId',
              name: 'ViewStoreChangeLog',
              component: ViewStoreChangeLog,
              props: true,
              // meta: {
              //   hide: true,
              // },
            },
          ]
        },
        {
          path: 'user',
          redirect: '/user/user/list',
          name: 'Users',
          meta: {
            label: 'usersList',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'user',
              name: 'UserList',
              redirect: '/user/user/list',
              meta: {
                hide: true,
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add',
                  name: 'AddUser',
                  component: AddUser,
                  meta: {
                    label: 'addUser',
                  },
                },
                {
                  path: 'update/:id',
                  name: 'UpdateUser',
                  component: UpdateUser,
                  props: true,
                  meta: {
                    label: 'editUser',
                  },
                },
                {
                  path: 'list',
                  name: 'UserList',
                  component: UserList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
            {
              path: 'alert',
              name: 'UserAlertsList',
              redirect: '/user/alert/list',
              meta: {
                label: 'usersBlackList',
              },
              component: {
                render (c) { return c('router-view') }
              },
              children: [
                {
                  path: 'add/:id',
                  name: 'AddUserAlert',
                  component: AddUserAlert,
                  props: true,
                },
                {
                  path: 'update/:id',
                  name: 'UpdateUserAlert',
                  component: UpdateUserAlert,
                  props: true,
                  meta: {
                    label: 'editUserAlert',
                  }
                },
                {
                  path: 'list',
                  name: 'UserListAlert',
                  component: UserAlertsList,
                  meta: {
                    hide: true,
                  },
                },
              ]
            },
          ]
        },
        {
          path: '/system',
          name: 'System',
          component: SystemList,
        },
        {
          path: 'statistic',
          redirect: '/statistic/search',
          name: 'StatisticSearchTerms',
          meta: {
            label: 'statistic',
          },
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'search',
              name: 'SearchTermList',
              component: SearchTermList,
              meta: {
                hide: true,
              },
            },
            {
              path: 'product',
              name: 'StatisticProductList',
              component: StatisticProductList,
              meta: {
                hide: true,
              },
            },
          ]
        },
        {
          path: 'translation',
          name: 'TranslationList',
          redirect: '/translation/list',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'add',
              name: 'AddTranslation',
              component: AddTranslation,
            },
            {
              path: 'update/:id',
              name: 'UpdateTranslation',
              component: UpdateTranslation,
            },
            {
              path: 'list',
              name: 'TranslationList',
              component: TranslationList,
              meta: {
                hide: true,
              },
            },
          ]
        },
        {
          path: 'icons',
          redirect: '/icons/font-awesome',
          name: 'Icons',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'CoreUI Icons',
              component: CoreUIIcons
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            },
            {
              path: 'font-awesome',
              name: 'Font Awesome',
              component: FontAwesome
            },
            {
              path: 'simple-line-icons',
              name: 'Simple Line Icons',
              component: SimpleLineIcons
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            }
          ]
        }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'logout',
          name: 'logout',
          component: Logout
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ],
})
