<template>
  <div>
    <b-form-group>
      <b-form-checkbox-group :disabled="disabled" v-model="value">
        <b-form-checkbox
          v-for="item in items"
          :key="getValue(item)"
          :value="item"
        >
          {{ getLabel(item) }}
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
  import { abstractField } from 'vue-form-generator'
  export default {
    mixins: [abstractField],
    data () {
      return {
        items: [],
        selected: [],
        labelField: 'name',
        valueField: 'id',
      }
    },
    async beforeMount() {
      if (this.schema.items) {
        this.items = this.schema.items instanceof Promise
          ? await this.schema.items
          : this.schema.items;
      }
      this.labelField = this.schema.labelField ? this.schema.labelField : 'name';
      this.valueField = this.schema.valueField ? this.schema.valueField : 'id';
    },
    methods: {
      getLabel (item) {
        return item[this.labelField] ? item[this.labelField] : '';
      },
      getValue (item) {
        return item[this.valueField] ? item[this.valueField] : '';
      }
    }
  }
</script>
