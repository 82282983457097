// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/es6/promise';
import 'core-js/es6/string';
import 'core-js/es7/array';
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueAuthHref from 'vue-auth-href'
import BootstrapVue from 'bootstrap-vue';
import App from './App';
import router from './router';
import VueFormGenerator from 'vue-form-generator';
import Vuelidate from 'vuelidate';
import store from './store/index';
import Multiselect from 'vue-multiselect';
import fieldAccordion from "./components/form-components/fieldAccordion";
import fieldImageUpload from "./components/form-components/fieldImageUpload";
import fieldEditor from "./components/form-components/fieldEditor";
import fieldDatepicker from "./components/form-components/fieldDatepicker";
import fieldCheckboxList from "./components/form-components/fieldCheckboxList";
import fieldTagMultiselect from "./components/form-components/fieldTagMultiselect";
import i18n from './plugins/i18n';
import VTree from 'vue-vtree';
import FieldArray from 'vfg-field-array';
import FieldObject from 'vfg-field-object';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import CKEditor from 'ckeditor4-vue';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.use(CKEditor);

// register globally
Vue.use(FieldArray);
Vue.use(FieldObject);
Vue.component('fieldAccordion', fieldAccordion);
Vue.component('fieldDatepicker', fieldDatepicker);
Vue.component('fieldImageUpload', fieldImageUpload);
Vue.component('fieldEditor', fieldEditor);
Vue.component('fieldCheckboxList', fieldCheckboxList);
Vue.component('fieldTagMultiselect', fieldTagMultiselect);
Vue.component('multiselect', Multiselect);

Vue.component('v-tree', VTree);

Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueFormGenerator);

Vue.prototype.$eventBus = new Vue();

const options = {
  token: () => localStorage.getItem('token'),
  textMode: "text",
  downloadingText: "Скачиваю...",
}
Vue.use(VueAuthHref, options)

const sentryDns = process.env.VUE_APP_SENTRY_DNS;

if (sentryDns) {
  Sentry.init({
    Vue,
    dsn: sentryDns,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["zoo-baza.com.ua", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
  });
}

router.beforeEach((to, from, next) => {
  const apiHost = process.env.VUE_APP_BASE_API_HOST;
  if (!apiHost && to.path !== '/pages/500') {
    next('/pages/500');
    return;
  }
  next();
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
