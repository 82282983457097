import Vue from 'vue';
import Vuex from 'vuex';
import moment from "moment";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    grids: {},
    logisticDate: localStorage.getItem('logisticDate') ? moment(localStorage.getItem('logisticDate')).toDate() : moment().utcOffset(0).startOf('day').toDate(),
    distributionDate: localStorage.getItem('distributionDate') ? moment(localStorage.getItem('distributionDate')).toDate() : moment().utcOffset(0).startOf('day').toDate(),
    smsListDate: localStorage.getItem('smsListDate') ? moment(localStorage.getItem('smsListDate')).toDate() : moment().utcOffset(0).startOf('day').toDate(),
  },
  actions: {
    FETCH_GRID: ({ commit, state }, token) => {
      return state.grids[token]
        ? Promise.resolve(state.grids[token])
        : new Promise((resolve) => {
          const gridData = {
            data: {
              rows: [],
              selectedRows: [],
              isLoading: false,
              totalRecords: 0,
              serverParams: {
                columnFilters: {},
                sort: {
                  field: '',
                  type: '',
                },
                page: 1,
                perPage: 10
              }
            },
            token
          }
          commit('SET_GRID', gridData)
        })
    },
    UPDATE_GRID: ({ commit }, payload) => {
      commit('SET_GRID', payload)
    },
    UPDATE_LOGISTIC_DATE: ({ commit }, payload) => {
      localStorage.setItem('logisticDate', payload)
      commit('SET_LOGISTIC_DATE', payload)
    },
    UPDATE_DISTRIBUTION_DATE: ({ commit }, payload) => {
      localStorage.setItem('distributionDate', payload)
      commit('SET_DISTRIBUTION_DATE', payload)
    },
    UPDATE_SMS_LIST_DATE: ({ commit }, payload) => {
      localStorage.setItem('smsListDate', payload)
      commit('SET_SMS_LIST_DATE', payload)
    },
  },
  mutations: {
    SET_GRID: (state, gridData) => {
      Vue.set(state.grids, gridData.token, gridData.data)
    },
    SET_LOGISTIC_DATE: (state, logisticDate) => {
      state.logisticDate = logisticDate
    },
    SET_DISTRIBUTION_DATE: (state, distributionDate) => {
      state.distributionDate = distributionDate
    },
    SET_SMS_LIST_DATE: (state, smsListDate) => {
      state.smsListDate = smsListDate
    },
  },
  getters: {
    GET_GRID: (state) => (token) => {
      return state.grids.hasOwnProperty(token) ? state.grids[token] : null
    },
    GET_LOGISTIC_DATE: (state) => state.logisticDate,
    GET_DISTRIBUTION_DATE: (state) => state.distributionDate,
    GET_SMS_LIST_DATE: (state) => state.smsListDate,
  }
})
