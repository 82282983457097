<template>
  <div>
    <multiselect
      v-model="value"
      :label="schema.urlField"
      :track-by="schema.trackByField"
      :placeholder="schema.placeholder"
      open-direction="bottom"
      :options="items"
      :multiple="true"
      :searchable="true"
      :loading="isLoading"
      :internal-search="false"
      :clear-on-select="true"
      :close-on-select="false"
      :show-no-results="false"
      :hide-selected="false"
      @search-change="asyncFind"
    />
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'
  import { abstractField } from 'vue-form-generator'
  import {APIService} from "../../services/api";

  export default {
    mixins: [abstractField],
    components: {
      Multiselect,
    },
    data () {
      return {
        items: [],
        isLoading: false
      }
    },
    methods: {
      async asyncFind (query) {
        if (query) {
          this.isLoading = true;
          try {
            this.items = await APIService.get(this.getSearchUrl(query));
          }
          catch(e) {
            console.error(e);
          }
          this.isLoading = false
        }
      },
      getSearchUrl(query){
        return this.schema.url + `?filter=${this.schema.urlField}||cont||${query}`;
      },
    }
  }
</script>
