import axios from 'axios';
import router from '../../router'

const token = localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('token');
    router.push({name:'Login'})
  }
  return error;
});

const baseUrl = process.env.VUE_APP_BASE_API_HOST;

export const APIService = {
  baseUrl: baseUrl,
  token: localStorage.getItem('token'),
  async get (requestPath) {
    const response = await axios.get(this.getFullUrl(requestPath), {
      headers: this.getCommonHeaders(),
    });
    return response.data;
  },
  async post (requestPath, data) {
    const response = await axios.post(this.getFullUrl(requestPath), data, {
      headers: this.getCommonHeaders(),
    });
    return response.data;
  },
  async put (requestPath, data) {
    const response = await axios.put(this.getFullUrl(requestPath), data, {
      headers: this.getCommonHeaders(),
    });
    return response.data;
  },
  async patch (requestPath, data) {
    const response = await axios.patch(this.getFullUrl(requestPath), data, {
      headers: this.getCommonHeaders(),
    });
    return response.data;
  },
  async delete (requestPath) {
    const response = await axios.delete(this.getFullUrl(requestPath), {
      headers: this.getCommonHeaders(),
    });
    return response.data;
  },
  getCommonHeaders() {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };
    if (this.token) {
      headers['Authorization'] = 'Bearer ' + this.token;
    }
    return headers;
  },
  getBaseUrl() {
    return this.baseUrl;
  },
  getFullUrl(requestPath) {
    return (requestPath.includes(this.baseUrl)) ? requestPath : this.baseUrl + requestPath;
  },
  getRestFullEntityUrl(url, data){
    return url + '/' + data.id;
  },
  setToken(token) {
    this.token = token;
  }
};




