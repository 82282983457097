<template>
  <b-row>
    <b-col sm="12">
      <b-row v-if="fileList.length > 0">
        <draggable
          :list="fileList"
          ghost-class="ghost"
          @start="dragging = true"
          @end="dragging = false"
        ><b-col xs="12" sm="6" md="4" lg="3" v-for="(file, i) in fileList" :key="file.fileName">
          <div class="image-container">
            <b-img :src="getImageUrl(file, suffix)"/>
            <span v-if="!disabled" @click="removeImage(i)">x</span>
            <b-input name="alt[]" placeholder="alt" :disabled="disabled" v-model="file.alt"/>
            <b-input name="title[]" placeholder="title" :disabled="disabled" v-model="file.title"/>
          </div>
        </b-col>
        </draggable>

      </b-row>
      <b-row v-else>
        <b-col xs="12" sm="6" md="4" lg="3">
          <div class="image-container">
            <b-img src="/img/default.jpeg"/>
          </div>
          <br />
        </b-col>
      </b-row>
    </b-col>
    <b-col xs="12" sm="6" md="4" lg="3">
      <vue-core-image-upload
        class="btn btn-primary"
        style="width: 100%;"
        :text="$t('uploadPhoto')"
        v-if="!disabled"
        :crop="false"
        @imageuploaded="imageUploaded"
        :data="data"
        :multiple="true"
        :max-file-size="5242880"
        :url="uploadUrl">
      </vue-core-image-upload>
      <b-button v-else class="btn btn-primary" style="width: 100%;" disabled>
        {{ $t('uploadPhoto') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
  import VueCoreImageUpload from 'vue-core-image-upload';
  import draggable from "vuedraggable";
  import {APIService} from "../services/api";

  export default {
    name: 'ImageUpload',
    components: {
      'vue-core-image-upload': VueCoreImageUpload,
      draggable,
    },
    props: {
      type: {
        type: String,
        required: true,
      },
      id: {
        type: Number,
        required: false,
      },
      disabled: {
        type: Number,
        required: false,
      },
      subtype: {
        type: Number,
        required: false,
        default: 0,
      },
      suffix: {
        type: String,
        required: false,
        default: 'original',
      },
    },
    data: () => {
      return {
        uploadUrl: '',
        fileList: [],
        data: {
          id: null,
          type: '',
          subtype: null,
        }
      }
    },
    async beforeMount() {
      const id = this.id ? this.id : Math.floor(1000 + Math.random() * 9000);
      this.uploadUrl = APIService.getBaseUrl() + 'image/upload';
      this.data = {
        id,
        type: this.type,
        subtype: this.subtype,
      };
      if (this.id) {
        try {
          const files = await APIService.get(`image/${id}/${this.type}`);
          this.updateFileList(files);
        } catch (e) {
          console.error(e);
        }
      }
    },
    methods : {
      imageUploaded: function(res) {
        const newList = this.fileList.concat(res);
        const key = 'imageName';
        const filesList = [...new Map(newList.map(item => [item[key], item])).values()];
        this.updateFileList(filesList);
      },
      getImageUrl(image, suffix) {
        const imageModificationData = image.modifications.filter(modificationData => modificationData.suffix === suffix);
        if (imageModificationData.length === 0) {
          return '';
        }
        return imageModificationData[0].fileUrl;
      },
      updateFileList(files) {
        files = files.map((file, i) => {
          file.orderKey = i + 1;
          file.ownerType = this.type;
          return file;
        })
        this.fileList = files;
        this.$emit('filesListUpdated', files);
      },
      removeImage(index) {
        const files = this.fileList;
        files.splice(index, 1);
        this.updateFileList(files);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .image-container{
    text-align: center;
    position: relative;
    padding-top: 1rem;
    span {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      color: #20a8d8;
      font-size: 16px;
      font-weight: bold;
    }
    input {
      margin: 5px 0;
    }
  }
</style>
